<script>
import axios from "axios";

export default {
  data() {
    return {
      inputText: "",
      img: "/image.png",
      name: "",
      job: "",
      jobSn: "",
      department: "",
      showBody: false, // 默认隐藏
    };
  },
  methods: {
    handleSubmit() {
      axios
        .get(`/api/getServiceInfo?key=${this.inputText}`)
        .then((response) => {
          var info = response.data.info;
          if (info) {
            this.img = info.img;
            this.name = info.name;
            this.job = info.job;
            this.jobSn = info.jobSn;
            this.department = info.department;
            this.showBody = true;
            console.log("查询成功:", info); // 打印成功信息到控制台
            // this.showMessageBox('查詢成功');
          } else {
            this.showBody = false;
            console.log("暂无人员"); // 打印失败信息到控制台
            // this.showMessageBox('暫無人員');
          }
        })
        .catch((error) => {
          console.error("查询失败:", error); // 打印错误信息到控制台
          this.showBody = false;
          //   this.showMessageBox('查詢失敗，請稍後再試');
        });
    },
    showMessageBox(message) {
      this.$message(message);
    },
  },
};
</script>

<template>
  <div id="app">
    <div class="top">
      <h2 style="color: #5c5c5c">服务人员验证</h2>

      <input v-model="inputText" type="text" style="height: 30px; margin-top: 20px; padding-left: 10px" placeholder="请输入"/>
      <button @click="handleSubmit"style=" margin: 20px; background: #f3b967; color: #fff; border: none; border-radius: 4px; font-size: 20px; padding: 10px 0;">立即验证</button>

      <p style="text-align: center; color: #5c5c5c; margin: 1rem; font-weight: 600">
        尊敬的人保使用者，您输入的工号验证结果
      </p>
    </div>

    <hr style="background: #f3b967; height: 1px; border: none" />

    <!-- 使用 v-show 控制显示与隐藏 -->
    <div class="body" v-if="showBody">
    <div class="avatar">
    <img id="avatarImage" :src="img" />
    </div>
    <div class="info d-a-f">
    <span id="nameSpan">姓名：{{ name }}</span>
    <span id="jobSpan">职务：{{ job }}</span>
    <span id="idSpan">职业编号证：{{ jobSn }}</span>
    <span id="departmentSpan">业务机构：{{ department }}</span>
    </div>
    <p style="text-align: center; color: #e9cb82">
    随时欢迎您的咨询，我将为您提供一站式的保险服务！
    </p>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.top {
  padding: 0 15px;
  margin-top: 1rem;
  display: flex;
  align-content: center;
  flex-direction: column;
}
.body {
  color: #5c5c5c;
  font-weight: 600;
}
.d-a-f {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.avatar {
  width: 150px;
  height: 150px;
  background: pink;
  margin: 10px auto;
}
img {
  width: 100%;
  height: 100%;
}
.info span {
  margin: 8px 0;
}
</style>
