<script>
export default {
  name: "formHt",
  data() {
    return {
      sn: [],
      name: "",
      code: "",
      year: "",
      month: "",
      day: "",
      startTime: "",
      endTime: "",
      divStyle: {
        background: `url(${require("../assets/a.jpg")}) no-repeat`,
        backgroundPosition: "97% 50%",
        backgroundSize: "27% 82%",
      },
    };
  },
  methods: {
    getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      var encodedValue = results[2].replace(/\+/g, " ");
      return decodeURIComponent(encodedValue);
    },
    formatDateToChinese(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // 月份从 0 开始，需要加 1
      let day = date.getDate();
      // 拼接为 "YYYY年M月D日" 的格式
      return year + " 年 " + month + " 月 " + day + " 日";
    },
    // 使用一个简单的哈希函数来生成数字
    simpleHash(inputString) {
      let hash = 0;
      for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    // 将哈希值转换为10位数字
    hashTo10Digits(inputString) {
      let hash = this.simpleHash(inputString);
      // 取hash的绝对值（确保为非负数），然后转换为字符串
      let hashStr = Math.abs(hash).toString();
      // 补全到10位数字
      while (hashStr.length < 10) {
        hashStr = "0" + hashStr;
      }
      // 取字符串的末尾10位数字作为结果
      let result = hashStr.slice(-10);
      return result;
    },
    redirectToHomepage() {
      window.location.href = "/";
    },
  },

  created() {
    this.code = this.$route.query.code;
    this.name = this.$route.query.name;
    let encryptedString = this.hashTo10Digits(this.code + this.name)
      .substring(0, 10)
      .toUpperCase();
    let currentDate = new Date();
    let year = currentDate.getFullYear().toString();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let day = currentDate.getDate().toString().padStart(2, "0");
    let yyyymmdd = year + month + day;
    this.sn = yyyymmdd + encryptedString;
    this.sn = "TXWB20243897789300";
    this.startTime = this.formatDateToChinese(currentDate);
    let endTimeValue = new Date();
    let newYear = endTimeValue.getFullYear() + 3;
    endTimeValue.setFullYear(newYear);
    this.endTime = this.formatDateToChinese(endTimeValue);
  },
};
</script>

<template>
  <div class="container">
    <div class="header d-a-j">
      <span>保单查询</span>
      <svg
        @click="redirectToHomepage"
        class="icon"
        viewBox="0 0 1024 1024"
        width="16"
        height="16"
      >
        <path
          d="M806.4 263.2l-45.6-45.6L512 467.2 263.2 217.6l-45.6 45.6L467.2 512 217.6 760.8l45.6 45.6L512 557.6l248.8 248.8 45.6-45.6L557.6 512z"
          fill="#ffffff"
        ></path>
      </svg>
    </div>
    <div
      class=""
      style="
        padding: 0 15px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
      "
    >
      <img style="width: 45%" src="../assets/images/logo.png" />
      <span style="color: #ff0000; font-size: 15px; align-self: end"
        >腾讯微保电子账户安全险</span
      >
    </div>
    <div class="body">
      <div class="item m-t d-a">
        <span style="margin-right: 10px">保单号码：{{ sn }} </span>
        <span>币值单位：人民币 </span>
      </div>

      <div class="item m-t d-a-j">
        <span>保单生成日期：{{ startTime }}-{{ endTime }} </span>
      </div>

      <div class="item">
        <span>投被保险人资料 </span>
      </div>
      <div class="d-f-a">
        <span class="span">投保人： 微保中心</span>
        <span class="span"
          >被保人姓名：{{ name }} 保险购买方式：微保人保财险（账户安全险）</span
        >
      </div>
      <div class="item m-t d-a-j">
        <span>保险计划：资金保障保险 </span>
      </div>
      <div class="d-j">
        <div class="left d-f-a">
          <h4>保险责任</h4>
          <span>适用条款：微保安心赔（可在微信、支付宝等第三方共享清单查看保单详情）</span>
          <span>保险类型：微保人保财险（账户安全险）</span>
          <span>保险费用：800 元/月，3 年合计 28800 元。 </span>
          <span>保障范围：个人支付账户（银行卡,零钱,零钱通,理财通,支付宝余额,余额宝和信用卡账户等）</span>
          <span>保障查询：提供第三方支付平台办理业务查询保险服务（微信,支付宝） </span>
          <span
            >保障说明：个人支付账户因被他人盗用而导致的资金损失,按损失金额承诺赔付,每年累积理赔次数不限次数。
          </span>
        </div>
        <div class="right" style="width: 170px">
          <img src="../assets/images/z.png" width="100%" style="margin-top: 10px" />
        </div>
      </div>
      <div class="item" style="margin: 10px 0">
        <span>特别约定 </span>
      </div>
      <div class="d-f-a">
        <span class="span"
          >1.第一次开通免费赠送的使用期即将到期（{{
            startTime
          }}）后开始正常收费，保费由客户承担，保费会从客户微信,支付宝或者银行卡，自动扣费。每月
          800 元，合计 3 年 28800 元。
        </span>
        <span class="span"
          >2.本保单为理赔保单，为期 3 年，<span style="color: #ff0000"
            >即刻生效，如需关闭需联系投保人通过银联数字代码关闭退保。
          </span></span
        >
        <span class="span"
          >3.
          重要提示理财通账户保险业务如未在规定时间内关闭,会自动从您名下银联账户扣除等额保险费用。
        </span>
      </div>

      <div class="item" style="margin: 10px 0">
        <span>服务热线 </span>
      </div>
      <div class="d-j">
        <div class="qr">
          <img src="../assets/images/qr.png" />
        </div>
        <div class="hotline">
          <h4>客服热线：<span style="color: #ff0000">95518</span></h4>
          <h4>财产热线：<span style="color: #ff0000">956008</span></h4>
          <h4>健康热线：<span style="color: #ff0000">400-6695518</span></h4>
          <h4>资金热线：<span style="color: #ff0000">400-8207999</span></h4>
        </div>
      </div>
      <div class="item" style="text-align: center; margin-top: 10px">
        <span>PICC 中国人民保险集团股份有限公司 </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  line-height: 1.3;
  font-size: 12px;
  background: #fff;
  color: #000000;
}

.header::before {
  content: none; /* 移除伪元素 */
}
.header:after {
  content: none; /* 移除伪元素 */
}
.header {
  background: #4476a7;
  color: #fff;
  padding: 10px 20px;
}

.d-a-j {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-j {
  display: flex;
  justify-content: space-between;
}

img {
  width: 100%;
}

.body {
  padding: 0 15px;
}

.body .item {
  background: #ff0000;
  color: #fff;
  padding: 3px 2px;
}

.m-t {
  margin-bottom: 30px;
}

.d-f-a {
  display: flex;
  flex-direction: column;
}

.d-f-a .span {
  margin: 6px 0;
}

.left span {
  margin: 2px 0;
}

.qr {
  width: 150px;
}

.hotline {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
