var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header d-a-j"},[_c('span',[_vm._v("保单查询")]),_c('svg',{staticClass:"icon",attrs:{"viewBox":"0 0 1024 1024","width":"16","height":"16"},on:{"click":_vm.redirectToHomepage}},[_c('path',{attrs:{"d":"M806.4 263.2l-45.6-45.6L512 467.2 263.2 217.6l-45.6 45.6L467.2 512 217.6 760.8l45.6 45.6L512 557.6l248.8 248.8 45.6-45.6L557.6 512z","fill":"#ffffff"}})])]),_vm._m(0),_c('div',{staticClass:"body"},[_c('div',{staticClass:"item m-t d-a"},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v("保单号码："+_vm._s(_vm.sn)+" ")]),_c('span',[_vm._v("币值单位：人民币 ")])]),_c('div',{staticClass:"item m-t d-a-j"},[_c('span',[_vm._v("保单生成日期："+_vm._s(_vm.startTime)+"-"+_vm._s(_vm.endTime)+" ")])]),_vm._m(1),_c('div',{staticClass:"d-f-a"},[_c('span',{staticClass:"span"},[_vm._v("投保人： 微保中心")]),_c('span',{staticClass:"span"},[_vm._v("被保人姓名："+_vm._s(_vm.name)+" 保险购买方式：微保人保财险（账户安全险）")])]),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"d-f-a"},[_c('span',{staticClass:"span"},[_vm._v("1.第一次开通免费赠送的使用期即将到期（"+_vm._s(_vm.startTime)+"）后开始正常收费，保费由客户承担，保费会从客户微信,支付宝或者银行卡，自动扣费。每月 800 元，合计 3 年 28800 元。 ")]),_vm._m(5),_c('span',{staticClass:"span"},[_vm._v("3. 重要提示理财通账户保险业务如未在规定时间内关闭,会自动从您名下银联账户扣除等额保险费用。 ")])]),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0 15px","margin":"10px 0","display":"flex","justify-content":"space-between"}},[_c('img',{staticStyle:{"width":"45%"},attrs:{"src":require("../assets/images/logo.png")}}),_c('span',{staticStyle:{"color":"#ff0000","font-size":"15px","align-self":"end"}},[_vm._v("腾讯微保电子账户安全险")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("投被保险人资料 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item m-t d-a-j"},[_c('span',[_vm._v("保险计划：资金保障保险 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-j"},[_c('div',{staticClass:"left d-f-a"},[_c('h4',[_vm._v("保险责任")]),_c('span',[_vm._v("适用条款：微保安心赔（可在微信、支付宝等第三方共享清单查看保单详情）")]),_c('span',[_vm._v("保险类型：微保人保财险（账户安全险）")]),_c('span',[_vm._v("保险费用：800 元/月，3 年合计 28800 元。 ")]),_c('span',[_vm._v("保障范围：个人支付账户（银行卡,零钱,零钱通,理财通,支付宝余额,余额宝和信用卡账户等）")]),_c('span',[_vm._v("保障查询：提供第三方支付平台办理业务查询保险服务（微信,支付宝） ")]),_c('span',[_vm._v("保障说明：个人支付账户因被他人盗用而导致的资金损失,按损失金额承诺赔付,每年累积理赔次数不限次数。 ")])]),_c('div',{staticClass:"right",staticStyle:{"width":"170px"}},[_c('img',{staticStyle:{"margin-top":"10px"},attrs:{"src":require("../assets/images/z.png"),"width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"margin":"10px 0"}},[_c('span',[_vm._v("特别约定 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"span"},[_vm._v("2.本保单为理赔保单，为期 3 年，"),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("即刻生效，如需关闭需联系投保人通过银联数字代码关闭退保。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"margin":"10px 0"}},[_c('span',[_vm._v("服务热线 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-j"},[_c('div',{staticClass:"qr"},[_c('img',{attrs:{"src":require("../assets/images/qr.png")}})]),_c('div',{staticClass:"hotline"},[_c('h4',[_vm._v("客服热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("95518")])]),_c('h4',[_vm._v("财产热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("956008")])]),_c('h4',[_vm._v("健康热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("400-6695518")])]),_c('h4',[_vm._v("资金热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("400-8207999")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"text-align":"center","margin-top":"10px"}},[_c('span',[_vm._v("PICC 中国人民保险集团股份有限公司 ")])])
}]

export { render, staticRenderFns }